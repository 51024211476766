<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required|min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="inpoDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Mobile Number"
          rules="min:8|max:14|required"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="inpoDetails.phone_number"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="inpoDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="inpoDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-form-row class="mb-2">
      <!-- Classification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Classification"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="inpoDetails.class_id"
              disabled
              :options="classifications"
              value-field="id"
              text-field="name"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Sub Classification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
          >
            <b-form-select
              v-model="inpoDetails.sub_class_id"
              :options="subClassifications"
              value-field="id"
              text-field="name"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Donation Category -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Donation Category"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              id="donation-category"
              v-model="inpoDetails.donation_cats_id"
              multiple
              label="category_name"
              :reduce="d => d.id"
              :options="donationCategories"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Language"
          rules=""
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="inpoDetails.language_id"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <location-inputs :details="inpoDetails" />

    <organization-upload-images
      :logo.sync="inpoDetails.logo"
      :cover.sync="inpoDetails.cover"
      :delete-enabled="false"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import organizationUploadImages from '@/common/components/common/Organizations/organizationUploadImages.vue'
import LocationInputs from '@/common/components/common/LocationInputs.vue'

export default {
  components: { organizationUploadImages, vSelect, LocationInputs },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: { type: Array, default: () => [] },
  },
  computed: {
    classID: {
      get() {
        return this.inpoDetails.class_id
      },
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">

</style>
