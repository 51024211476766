var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form-wizard',{staticClass:"mb-3 p-3",attrs:{"color":"#00A5AF","shape":"square","title":null,"subtitle":null,"finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return handleSubmit(_vm.insertInpo)}}},[_c('tab-content',{attrs:{"before-change":function (){
        if(_vm.$refs.inpoDetails.$data.flags.invalid)
          { _vm.$refs.inpoDetails.handleSubmit(); }
        return !_vm.$refs.inpoDetails.$data.flags.invalid
      },"title":"Inpo Details"}},[_c('validation-observer',{ref:"inpoDetails"},[_c('inpo-details',{attrs:{"inpo-details":_vm.inpoData,"options":_vm.options,"languages":_vm.languages,"donation-categories":_vm.activeDonationCategories,"classifications":_vm.classifications,"sub-classifications":_vm.subClass}})],1),_c('div',{staticClass:"btn-back"},[_c('back')],1)],1),_c('tab-content',{attrs:{"before-change":function (){
        if(_vm.$refs.inpoSocial.$data.flags.invalid)
          { _vm.$refs.inpoSocial.handleSubmit(); }
        return !_vm.$refs.inpoSocial.$data.flags.invalid
      },"title":"Social"}},[_c('validation-observer',{ref:"inpoSocial"},[_c('inpo-social',{attrs:{"inpo-details":_vm.inpoData,"tag-options":_vm.tags}})],1)],1),_c('tab-content',{attrs:{"before-change":function (){
        if(_vm.$refs.paymentDetails.$data.flags.invalid)
          { _vm.$refs.paymentDetails.handleSubmit(); }
        return !_vm.$refs.paymentDetails.$data.flags.invalid
      },"title":"Payment Details"}},[_c('validation-observer',{ref:"paymentDetails"},[_c('payment-details',{attrs:{"payment-details-data":_vm.inpoData}}),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('upload-image',{attrs:{"label":"Signature","image-id":"signature","preview":_vm.inpoData.sign_path,"image":_vm.inpoData.sign_path},on:{"update:preview":function($event){return _vm.$set(_vm.inpoData, "sign_path", $event)},"update:image":function($event){return _vm.$set(_vm.inpoData, "sign_path", $event)}}})],1)],1)],1)],1),_c('upload-progress',{attrs:{"progress":_vm.progress}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }