<template>
  <validation-observer
    ref="infoRules"
    tag="form"
  >
    <b-row>
      <!------------- Payment Type --------------->
      <b-col
        cols="12"
        class="pt-1"
      >
        <div class="payment">
          <h5>Payment Details</h5>
        </div>
      </b-col>

      <!-- Payment Type -->
      <b-col
        md="4"
      >
        <validation-provider
          #default="validationContext"
          name="Payment Type"
        >
          <b-form-group
            label="Payment Type"
            label-for="payment-type"
          >
            <b-form-select
              id="payment-type"
              v-model="paymentDetailsData.payment_type"
              :options="paymentType"
              value-field="value"
              text-field="text"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="4"
      >
        <validation-provider
          #default="validationContext"
          name="Business Type"
        >
          <b-form-group
            label="Business Type"
            label-for="business-type"
          >
            <b-form-select
              id="business-type"
              v-model="paymentDetailsData.business_type"
              :options="businessType"
              value-field="value"
              text-field="text"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Business Name -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Business Name"
        >
          <b-form-group
            label="Business Name"
            label-for="business-name"
          >
            <b-form-input
              id="business-name"
              v-model="paymentDetailsData.business_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Business Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Legal Name -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Legal Name"
        >
          <b-form-group
            label="Legal Name"
            label-for="legal-name"
          >
            <b-form-input
              id="legal-name"
              v-model="paymentDetailsData.legal_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Legal Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Transit Number -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Transit Number"
        >
          <b-form-group
            label="Transit Number (Branch number)"
            label-for="transit-number"
          >
            <b-form-input
              id="transit-number"
              v-model="paymentDetailsData.transit_number"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Transit Number"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Institution Number -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Institution Number"
        >
          <b-form-group
            label="Institution Number (Bank number)"
            label-for="institution-number"
          >
            <b-form-input
              id="institution-number"
              v-model="paymentDetailsData.institution_number"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Institution Number"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Account Number -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Account Number"
        >
          <b-form-group
            label="Account Number"
            label-for="account-number"
          >
            <b-form-input
              id="account-number"
              v-model="paymentDetailsData.account_number"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Account Number"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Charitable Number -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Charitable Number"
        >
          <b-form-group
            label="Charitable Number"
            label-for="charitable-number"
          >
            <b-form-input
              id="charitable-number"
              v-model="paymentDetailsData.charitable_number"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Charitable Number"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Incorporation number -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Incorporation number"
        >
          <b-form-group
            label="Incorporation number"
            label-for="incorporation-number"
          >
            <b-form-input
              id="incorporation-number"
              v-model="paymentDetailsData.phoneNum"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Incorporation number"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        cols="12"
      >
        <div class="payment">
          <h6>Owners</h6>
        </div>
      </b-col>

      <!-- Account Holder Name -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Account Holder Name"
        >
          <b-form-group
            label="Account Holder Name"
            label-for="account-holder-name"
          >
            <b-form-input
              id="account-holder-name"
              v-model="paymentDetailsData.account_holder_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Account Holder Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Account Holder Position -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Account Holder Position"
        >
          <b-form-group
            label="Account Holder Position"
            label-for="account-holder-position"
          >
            <b-form-input
              id="account-holder-position"
              v-model="paymentDetailsData.account_holder_position"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Account Holder Position"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Account Holder Date Of Birth -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Account Holder Date Of Birth"
          :detect-input="false"
        >
          <b-form-group
            label="Account Holder Date Of Birth"
            label-for="account-holder-dofb"
          >
            <flat-pickr
              id="account-holder-dofb"
              v-model="paymentDetailsData.account_holder_dofb"
              class="form-control mb-1"
              :config="{ enableTime: false, dateFormat: 'Y-m-d', maxDate: 'today' }"
              @input="validationContext.validate(paymentDetailsData.account_holder_dofb)"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    paymentDetailsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      paymentType: [
        { value: 'credit', text: 'Credit Card' },
        { value: 'bank', text: 'Bank Account' },
        { value: 'tax_receipt', text: 'Tax Receipt' },
      ],
      businessType: [
        { value: 'ltd_corperate', text: 'LTD Cotporate' },
        { value: 'nonprofit', text: 'Non Profit' },
        { value: 'partnership', text: 'Partnership' },
        { value: 'proprietorship', text: 'Proprietorship' },
      ],
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
