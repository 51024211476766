<template>
  <!-- eslint-disable vue/no-template-shadow -->
  <validation-observer v-slot="{handleSubmit}">
    <form-wizard
      color="#00A5AF"
      shape="square"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="handleSubmit(insertInpo)"
    >
      <tab-content
        :before-change="()=>{
          if($refs.inpoDetails.$data.flags.invalid)
            $refs.inpoDetails.handleSubmit();
          return !$refs.inpoDetails.$data.flags.invalid
        }"
        title="Inpo Details"
      >
        <validation-observer ref="inpoDetails">
          <inpo-details
            :inpo-details="inpoData"
            :options="options"
            :languages="languages"
            :donation-categories="activeDonationCategories"
            :classifications="classifications"
            :sub-classifications="subClass"
          />
        </validation-observer>
        <div class="btn-back">
          <back />
        </div>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.inpoSocial.$data.flags.invalid)
            $refs.inpoSocial.handleSubmit();
          return !$refs.inpoSocial.$data.flags.invalid
        }"
        title="Social"
      >
        <validation-observer ref="inpoSocial">
          <inpo-social
            :inpo-details="inpoData"
            :tag-options="tags"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.paymentDetails.$data.flags.invalid)
            $refs.paymentDetails.handleSubmit();
          return !$refs.paymentDetails.$data.flags.invalid
        }"
        title="Payment Details"
      >
        <validation-observer ref="paymentDetails">
          <payment-details
            :payment-details-data="inpoData"
          />
          <b-row>
            <b-col md="4">
              <upload-image
                label="Signature"
                image-id="signature"
                :preview.sync="inpoData.sign_path"
                :image.sync="inpoData.sign_path"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <upload-progress :progress="progress" />
    </form-wizard>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import inpoDetails from '@/common/components/Inpos/Add/InpoDetails.vue'
import InpoSocial from '@/common/components/Inpos/Add/InpoSocial.vue'
import paymentDetails from '@/common/components/Inpos/Add/PaymentDetails.vue'
import UploadProgress from '@/common/components/common/Organizations/UploadProgress.vue'
import UploadImage from '@/common/components/common/UploadImages.vue'

import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/Back.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    inpoDetails,
    InpoSocial,
    paymentDetails,
    UploadProgress,
    UploadImage,
    Back,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inpoData: {
        name: '',
        lat: '',
        lng: '',
        logo: '',
        cover: '',
        about: '',
        phone_number: '',
        email: '',
        social_facebook: '',
        social_twitter: '',
        social_instagram: '',
        social_youtube: '',
        website: '',
        class_id: 1,
        sub_class_id: '',
        address_type: 1,
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        service_description: '',
        language_id: '',
        tag_id: '',
        payment_type: '',
        business_type: '',
        business_name: '',
        legal_name: '',
        account_holder_name: '',
        account_holder_position: '',
        account_holder_dofb: '',
        transit_number: '',
        institution_number: '',
        account_number: '',
        phoneNum: '',
        charitable_number: '',
        donation_cats_id: '',
      },
      options: ['English', 'Arabic', 'German', 'other'],
      autocomplete: '',
      place: '',
      classifications: [
        {
          id: 1,
          name: 'Organization',
        },
      ],
    }
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const { formData, setFormData } = handleFormData()
    const {
      showErrors,
      successfulOperationAlert,
    } = handleAlerts()

    const {
      activeDonationCategories,
      donationCategories,
      languages,
      tags,
    } = getOrganizationDetails()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      donationCategories,
      activeDonationCategories,
      languages,
      tags,
      formData,
      setFormData,
      showErrors,
      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  computed: {
    ...mapGetters({ subClass: 'generalSettings/getSubClassifications' }),
  },
  created() {
    this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classifications[0].id)
  },
  methods: {
    insertInpo() {
      this.swapLatAndLong()
      this.setFormData(this.inpoData)
      this.$entities
        .post('internalops/entity', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        })
        .then(() => {
          this.successfulOperationAlert('Added Successfully')
          this.$router.push('/inpos/list')
        })
        .catch(errors => this.showErrors(errors.response.data.errors))
    },
    swapLatAndLong() {
      [this.inpoData.lat, this.inpoData.lng] = [this.inpoData.lng, this.inpoData.lat] // this is because api reverse them
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
